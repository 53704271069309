import Vue from 'vue'
import VueRouter from 'vue-router'
import routesExt from './router_ext'
import routesExamples from './router_examples'

Vue.use(VueRouter)

const Login = {
    template: '<div>Login</div>'
}

let routes = [
    {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/',
        name: 'Frontpage',
        component: () => import('@/views/Frontpage.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            showUserInfo: true,
            requiresAuth: true
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login.vue')
    },
    {
        path: '/register',
        name: 'Registrasi',
        component: () => import('@/views/Register.vue')
    },
    {
        path: '/pending-registration',
        name: 'Proses Registrasi',
        component: () => import('@/views/PendingRegistration.vue')
    },
    {
        path: '/forgot-password',
        name: 'Lupa Password',
        component: () => import('@/views/ForgotPassword.vue')
    },
    {
        path: '/complete-form',
        name: 'Kelengkapan Data',
        component: () => import('@/views/CompleteRegistration.vue')
    },
    /*{
        path: '/kalender-belajar',
        name: 'Kalender Belajar',
        component: () => import('@/views/KalenderBelajar.vue')
    },*/
    {
        path: '/berita',
        name: 'Berita',
        component: () => import('@/views/Login.vue')
    },
    {
        path: '/promosi',
        name: 'Promosi',
        component: () => import('@/views/Login.vue')
    },
    {
        path: '/promosi-detail/:id',
        name: 'Detail Promosi',
        component: () => import('@/views/Berita.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/berita',
        name: 'Berita',
        component: () => import('@/views/Login.vue')
    },
    {
        path: '/berita-detail/:id',
        name: 'Detail Berita',
        component: () => import('@/views/Berita.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/absensi',
        name: 'Daftar Kehadiran',
        component: () => import('@/views/DaftarKehadiran.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/diskusi',
        name: 'Diskusi Siswa',
        component: () => import('@/views/Diskusi.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/diskusi-filter/:id',
        name: 'Diskusi Siswa Kelas',
        component: () => import('@/views/DiskusiFilter.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/diskusi-kelas',
        name: 'Diskusi Kelas',
        component: () => import('@/views/DiskusiKelas.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/detail-diskusi',
        name: 'Detail Diskusi',
        component: () => import('@/views/Tanya.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/chat-admin',
        name: 'Chat Admin',
        component: () => import('@/views/Login.vue')
    },
    {
        path: '/materi-ajar',
        name: 'Mata Pelajaran',
        component: () => import('@/views/Materi.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/materi-ajar-content/:id',
        name: 'Materi',
        component: () => import('@/views/MateriList.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }        
    },
    {
        path: '/materi-ajar-content-detail/:id',
        name: 'Detail Materi',
        component: () => import('@/views/MateriDetail.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/soal',
        name: 'Soal Latihan',
        component: () => import('@/views/Soal.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/soal-mapel',
        name: 'Daftar Mapel Soal',
        component: () => import('@/views/SoalMapel.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/soal-mapel-list/:id',
        name: 'Daftar Soal Latihan',
        component: () => import('@/views/SoalMapelList.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/soal-start',
        name: 'Info Mulai Soal',
        component: () => import('@/views/SoalStart.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }        
    },
    {
        path: '/soal-start/:id',
        name: 'Info Mulai Soal',
        component: () => import('@/views/SoalStart.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }        
    },
    {
        path: '/soal-process',
        name: 'Soal Latihan',
        component: () => import('@/views/SoalProcess.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/soal-finish',
        name: 'Soal Latihan Selesai',
        component: () => import('@/views/SoalFinish.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },

    {
        path: '/ujian',
        name: 'Ujian',
        component: () => import('@/views/Ujian.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/ujian-mapel',
        name: 'Daftar Mapel Ujian',
        component: () => import('@/views/UjianMapel.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/ujian-mapel-list/:id/:type',
        name: 'Daftar Ujian',
        component: () => import('@/views/UjianMapelList.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/ujian-start',
        name: 'Info Mulai Ujian',
        component: () => import('@/views/UjianStart.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }        
    },
    {
        path: '/ujian-start/:id',
        name: 'Info Mulai Ujian',
        component: () => import('@/views/UjianStart.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }        
    },
    {
        path: '/ujian-process',
        name: 'Ujian',
        component: () => import('@/views/UjianProcess.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/ujian-process/:id',
        name: 'Ujian',
        component: () => import('@/views/UjianProcess.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/ujian-finish',
        name: 'Ujian Selesai',
        component: () => import('@/views/UjianFinish.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/ujian-finish/:id/:mapel_id/:type',
        name: 'Pembahasan Soal',
        component: () => import('@/views/UjianFinish.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/ujian-finish-hasil/:id/:mapel_id/:type',
        name: 'Pembahasan Soal',
        component: () => import('@/views/UjianFinishHasil.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/profile',
        name: 'User Profile',
        component: () => import('@/views/Profile.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        },
    },
    {
        path: '/about',
        name: 'Tentang Aplikasi',
        component: () => import('@/views/About.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        },
    },
    {
        path: '/lessonplan',
        name: 'Lesson Plan',
        component: () => import('@/views/Lessonplan.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
]

routes = routes.concat(routesExt)
routes = routes.concat(routesExamples)
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        console.log('auth')
            console.log('record', to)
            //Vue.$root.$emit('onRouteChanged', 'new message!');
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!localStorage.APP_TOKEN) {
            next({ name: 'Login' })

        } 
        else {
            // get permission info from server
            setTimeout(function(){
                to.params.roles = ["create", "read", "update", "delete"];
                //record.meta.roles = record.name+Date.now();{query:{roles:record.name+Date.now()}}
                next({params:to.params}) // does not require auth, make sure to always call next()!
            }, 100);
            
        }
    } else {
        if(localStorage.APP_TOKEN && to.path === '/login'){
            //next({ path: '/' })
            next()
        } 
        else next() // does not require auth, make sure to always call next()!
    }
})
export default router
