<template>
  <nav id="sidemenu" class="main-header navbar navbar-expand navbar-white navbar-dark" style="
    background: linear-gradient(90deg, #6190E8 0%, #A7BFE8 100%);">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item"><router-link class="nav-link" to="/" style="padding:0;">
      <img height="32" src="@/assets/img/logo-white-only.png">
      </router-link></li>
      <li class="nav-item text-light"><h5 class="mt-2 pl-2">{{title}}</h5></li>
    </ul>

    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <a class="nav-link" style="padding:0;margin-right:5px;" href="#" ><img class="p-0 img-circle img-bordered-sm" style="border:2px solid #fff;" height="32" width="32" :src="imgUrl" alt="user image"></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#" data-widget="pushmenu"><i class="fas fa-bars"></i>
      </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import {auth} from '@/libs/hxcore';
import '@/assets/style.css';
import eventBus from '@/eventBus';
export default {
  name: "AppLayoutNav",
  data: function(){
    return {
      name : '',
      imgUrl : '',
      defaultTitle : 'TARUNA CENDEKIA',
      title: 'TARUNA CENDEKIA'
    }
  },
  watch:{
    $route (to, from){
        if(to.path!=='/')this.title = to.name;
        else this.title = this.defaultTitle;
      }
  } ,
  created: function(){
    var info = auth.user();
    console.log('user', info)
    if(info)
    {
      this.name = info.display_name;
      this.imgUrl = info.img_url;
    }
    else{
      this.imgUrl = '/dist/img/user.jpeg';
    }
    
    
  },
  methods: {
    signOut: function(e) {
      console.log('signout')
      auth.logout();
      this.$router.push('/login');
      e.preventDefault();
    }
  },
  mounted: function() {
    eventBus.$on('changeFoto', (data) => { // here you need to use the arrow function
      var info = auth.user();
      if(info)this.info = info;
      var file = data.message[0].code;
      this.imgUrl = window.$apiUrl+'/file/image/'+file;
    })
  }
}
</script>

<style scoped>

</style>
