<template>
  <div v-if="isAdmin">
    <div class="wrapper">
      <AppLayoutNav />
      <div class="main-sidebarx"></div>
      <AppLayoutMenu />
      <div class="content-wrapper">

      <section v-if="showUserInfo" class="content-header" style="background: linear-gradient(90deg, rgb(97, 144, 232) 0%, rgb(167, 191, 232) 100%);">
        <div class="container-fluid">
          <userinfo></userinfo>
        </div><!-- /.container-fluid -->
      </section>
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
          </div>
        </div><!-- /.container-fluid -->
      </section>

      <slot />
      </div>
      <footer class="main-footer">
        <div class="float-right d-none d-sm-block">
          <b>Version</b> 1.1
        </div>
        <strong>Copyright &copy; 2021 <a href="https://tarunacendekia.com">tarunacendekia.com</a>.</strong> All rights
        reserved.
      </footer>
    </div>
  </div>
  <div v-else>
    <slot />
  </div>
</template>
<script>

import $ from 'jquery';
import AppLayoutNav from '@/layouts/AppLayoutNav'
import AppLayoutMenu from '@/layouts/AppLayoutMenu'
import Userinfo from '@/components/Userinfo'
const defaultLayout = 'AppLayoutDefault'

export default {
  name: "AppLayout",
  data() {
    return {
      title: null,
      showUserInfo: false
    };
  },
  watch:{
    $route (to, from){
        this.title = to.name;
        if(from.path=='/login')
        {
          $('body').removeClass('login-page')
        }
        if(to.meta && to.meta.showUserInfo)
        {
          this.showUserInfo = true;
        }
        else{
          this.showUserInfo = false;
        }
      }
  } ,
  mounted(){
    this.title = this.$route.name;

    $('body').removeClass('login-page')
    console.log('mounted ',this.$route)
    this.$root.$on('onRouteChanged', (data) => { // here you need to use the arrow function
     console.log(data)
    })
  },
  created: function () {
  },
  computed: {
    layout() {
      const layout = this.$route.meta.layout || defaultLayout
      return () => import(`@/layouts/${layout}.vue`)
    },
    isAdmin(){
      console.log(this.$route.meta.layout)
      var nilai = (this.$route.meta.layout=='AppLayoutAdmin')?true:false;
      //console.log(nilai);
      return nilai;
    }
  },
  components: {
    AppLayoutNav, AppLayoutMenu, Userinfo
  }
}
</script>
<style>

</style>