<template>
    <div class="">
        <div class="user-block shadow clearfix mt-3" style="margin-bottom: -75px;">
            <img class="img-bordered-sm" :src="photo" alt="gambar profile">
            <span class="username mb-3">
                <span style="color:#6391E8;">{{info.display_name}}</span>
            </span>
            <span class="description" style="color:black;"><b>Mapel</b> {{tentor.mapel}}</span>
        </div>
    </div>
</template>
<script>
import {auth, authFetch} from '@/libs/hxcore';
import $ from 'jquery';
export default {
    name: 'Userinfo',
    props: ['value', 'options'],
    data() {
        return {
            info: {
                display_name: 'No Name'
            },
            tentor: {},
            photo: '',
        };
    },
    created: function(){
        var info = auth.user();
        console.log('info user',info)
        if(info)this.info = info;

        this.loadGuru(auth.user().id);
    },
    methods:{
        loadGuru(id){
            authFetch('/tentor/frontpage/info/'+id)
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.tentor = js.data;
                this.photo = !this.tentor.file_name ? window.$apiUrl+"/uploads/default/unknown_user.jpg" : window.$apiUrl+'/file/image/'+js.data.code;
            });
        },
    },
    mounted: function() {

    },
    beforeDestroy: function() {
        //$(this.$el).datepicker('hide').datepicker('destroy');
    },
    watch: {
        value: function(old, val) {
            //$(this.$el).val(val);
        }
    }
}
</script>
<style scoped>
.user-block {
    width: 315px;
    margin: 0 auto;
    float: none !important;
    background-color: #fff;
    padding: 20px 20px 20px 20px;
    border-radius: 13px 13px 13px 13px;
}
.user-block img {
    float: left;
    height: 80px;
    width: 80px;
}
.user-block .comment, .user-block .description, .user-block .username {
    display: block;
    margin-left: 100px;
}

@media only screen and (min-width: 650px) {
    .user-block {
        width: 466px;
    }
}
</style>