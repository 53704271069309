<template>
    <aside class="main-sidebar sidebar-light-olive">
        <!-- <div class="box-title" style="text-align: -webkit-center;background: white;">
            <router-link to="/" class="logo-height">
                <img src="@/assets/img/logo-text2.png" alt="Taruna Logo" class="wide logo-height" style="width: 100%;height: auto;position: relative;top: 50%;transform: translateY(0%);margin-left: 20px;">
                </router-link>
        </div> -->
        <!-- Sidebar -->
        <div class="sidebar">
            <!-- Sidebar Menu -->
            <nav class="mt-2">
                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false" v-html="getSideMenu" @click="handleClick">
                    <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
                    <li class="nav-header">Examples</li>
                    <li class="nav-item">
                        <router-link to="/table" class="nav-link">
                            <i class="fas fa-circle nav-icon"></i>
                            <p>Table</p>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/form" class="nav-link">
                            <i class="fas fa-circle nav-icon"></i>
                            <p>Form</p>
                        </router-link>
                    </li>
                </ul>
            </nav>
            <!-- /.sidebar-menu -->
        </div>
        <!-- /.sidebar -->
    </aside>
</template>
<script>
import { auth } from '@/libs/hxcore';
import sideMenuAdmin from '@/sidemenu';
export default {
    name: "AppLayoutMenu",
    data() {
        return {
            user: null,
            sideMenu: ''
        };
    },
    computed: {
        getAvatar: function() {
            return this.user ? this.user.avatar : ''
        },
        getSideMenu: function() {
            var info = auth.user();
            console.log('sidemenu', info)
            if (1) {
                var output = '<li class="nav-header">MENU</li>';
                return output + this.getWalker(sideMenuAdmin);
            }

        }
    },
    methods: {
        handleClick(e) {
            var target = e.target.closest('.nav-link');
            if (target) {
                //target.classList.add('active');
                if (target.getAttribute('href') == '#') {
                    e.preventDefault();
                    return;
                }
                if (target.getAttribute('href') == '/logout') {
                    auth.logout();
                    this.$router.push('/login');
                    e.preventDefault();
                    return;
                }
                if (this.$route.path != target.getAttribute('href'))
                    this.$router.push({ path: target.getAttribute('href') })
                e.preventDefault();
            }

        },
        getWalker(menus) {
            var output = '';
            var currPath = this.$route.path;
            for (var k in menus) {
                var menu = menus[k];
                var clsLink = '';
                if (currPath == menu.url) clsLink = 'active';
                var tmp = '<li class="' + (menu.menus ? 'nav-item has-treeview' : 'nav-item') + '">';
                tmp += '<a style="cursor:pointer;" to="' + menu.url + '" href="' + menu.url + '" class="nav-link ' + clsLink + '">';
                tmp += '<i class="' + menu.icon + ' nav-icon"></i>';
                tmp += '<p>' + menu.label + (menu.menus ? '<i class="right fas fa-angle-left"></i>' : '') + '</p>';
                tmp += '</a>';
                if (menu.menus) {
                    tmp += '<ul class="nav nav-treeview">';
                    tmp += this.getWalker(menu.menus);
                    tmp += '</ul>';
                    console.log(menu.menus)
                }
                tmp += '</li>';
                output += tmp;
            }
            return output;
        }
    },
    created() {
        // Simulate fetching user data.
        setTimeout(() => {
            this.user = { name: `John Doe`, avatar: 'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y' };
        }, 2000);
    },
    mounted() {
        //require ('@/libs/adminlte')
    }
}
</script>
<style scoped>
.sidebar-collapse .lite {
    padding: 19px;
    position: relative;
    
    transform: translateY(-50%);
    margin-left: -10px;
}
</style>