const routes = [
	{
        path: '/materi',
        name: 'Materi',
        component: () => import('@/views/Materi.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/kalender',
        name: 'Kalender',
        component: () => import('@/views/Kalender.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/kalender/:id',
        name: 'Detail Kalender',
        component: () => import('@/views/Kalender.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'core.roles'
        }
    },
    {
        path: '/tanya-guru',
        name: 'Diskusi Siswa',
        component: () => import('@/views/Tanya.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/soal', 
        name: 'Soal',
        component: () => import('@/views/Soal.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/ujian',
        name: 'Ujian',
        component: () => import('@/views/Soal.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/contact',
        name: 'Hubungi Admin',
        component: () => import('@/views/Contact.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },

    
]

export default routes
