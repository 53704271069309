import Vue from 'vue'
import App from './App.vue'
import router from './router'
import AppLayout from '@/layouts/AppLayout'
import GoogleAuth from '@/libs/google.js'
//import $ from 'jquery';
//window.$ = window.jQuery = $;

//console.log(dt)
require ('@/libs/adminlte')
require ('@/libs/bootstrap.min')
import '@/assets/style.css'
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import './registerServiceWorker'

//Vue.component('AppLayout', AppLayout)

const gauthOption = {
  clientId: '707231563844-e5cpkqrlt62gncmj6b84of5sml9lp8g9.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account'
}
Vue.use(GoogleAuth, gauthOption)

Vue.config.productionTip = false
Vue.config.enableChat = false

// window.$apiUrl = 'https://siswa.tarunacendekia.com/api';
window.$apiUrl = 'http://localhost/lbb-taruna/backend/api';
if (process.env.NODE_ENV === 'production') {
  var url = process.env.VUE_APP_ENDPOINT;
  if(url)window.$apiUrl  = url;
  else window.$apiUrl = 'https://siswa.tarunacendekia.com/api';
	window.$crossDomain = false;
}
//Vue.prototype.jQuery = jQuery
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
