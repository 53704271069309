<template>
  <div id="app">
    <AppLayout>
      <router-view />
    </AppLayout>
</div>
</template>
<script>
import { Plugins, NetworkStatus, PluginListenerHandle, Device, Capacitor } from '@capacitor/core';
import AppLayout from '@/layouts/AppLayout';
export default {
  name: "App",
  created: function(){
  	console.log('created app');
  	if (Capacitor.isNative) {
	      Plugins.App.addListener('backButton', e => {
		console.log('back');
		if (window.location.pathname === '/home' || window.location.pathname === '/') {          
		    Plugins.App.exitApp()
		} else {
		  window.history.back();
		}
	      })
	    }
  },
  components: {
    AppLayout
  }
}
</script>
<style>
</style>
